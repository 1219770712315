import React from 'react';
import { Typography } from 'Components/atoms';
import { DropdownComponent } from 'Components/molecules';
import { TextArea } from 'Components/molecules';
import { METHOD_OPTIONS, PAYLOAD_OPTIONS } from './Data';
import {
    ACTION_LABELS,
    ACTION_TABLE_HEADERS,
    ADD_HEADER,
    ADD_PARAMETER,
    CUSTOM_ACTION_LABELS,
    CustomActionsLabelMap,
    ENTER_VALID_FORMAT,
    INVALID_URL_TEXT,
    SUPPORTING_TEXT,
} from 'Core-utils/constants/constants';
import TableForm from './TableForm';
import { CustomActionDto } from '@ampsec/platform-client';
import { FormControl } from 'react-bootstrap';
import classNames from 'classnames';
import { useI18n } from 'Hooks/useI18n';
import { CustomTooltip } from 'Components/atoms';
import { IoMdInformationCircleOutline } from '@react-icons/all-files/io/IoMdInformationCircleOutline';
import '../styles.scss';

const renderInputField = (
    label: string,
    value: string,
    onValueChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
    isTableRow: boolean,
    tooltipLabel: string,
) => {
    const className = isTableRow ? 'action-block__custom-table-input' : 'action-block__form-input';
    return (
        <div
            className={classNames('position-relative input-field d-flex flex-column gap-2', {
                [className ?? 'unknown']: Boolean(className),
            })}
        >
            <div className="d-flex gap-1">
                <Typography variant="body3" color="text-medium-emphasis">
                    {label}
                </Typography>
                {label && (
                    <CustomTooltip label={tooltipLabel}>
                        <IoMdInformationCircleOutline className="text-text-low-emphasis" />
                    </CustomTooltip>
                )}
            </div>
            <FormControl value={value} className="input-field__input bg-transparent" onChange={onValueChange} />
        </div>
    );
};

const renderTextAreaField = (
    label: string,
    value: string,
    onValueChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
    isJsonData: boolean = false,
    textLimit?: number,
) => {
    const { t } = useI18n();
    return (
        <div className="d-flex flex-column gap-2">
            <div className="d-flex gap-1">
                <Typography variant="body3" color="text-medium-emphasis">
                    {label}
                </Typography>
                {label && (
                    <CustomTooltip label={t(CustomActionsLabelMap[label])}>
                        <IoMdInformationCircleOutline className="text-text-low-emphasis" />
                    </CustomTooltip>
                )}
            </div>
            <TextArea value={value} onValueChange={onValueChange} textLimit={textLimit} isJsonData={isJsonData} />
        </div>
    );
};

const handleChange = (onChange: (value: string) => void) => (eventKey: string | null) => {
    onChange(eventKey || '');
};

const renderDropdown = (
    label: string,
    items: { id: string; value: string }[],
    value: string,
    onChange: (value: string) => void,
) => {
    const { t } = useI18n();
    return (
        <div className="d-flex flex-column gap-2">
            <div className="d-flex gap-1">
                <Typography variant="body3" color="text-medium-emphasis">
                    {label}
                </Typography>
                <CustomTooltip label={t(CustomActionsLabelMap[label])}>
                    <IoMdInformationCircleOutline className="text-text-low-emphasis" />
                </CustomTooltip>
            </div>
            <DropdownComponent value={value} dropDownItems={items} onChange={handleChange(onChange)} />
        </div>
    );
};

const renderJSONError = (error: string, supportingText?: string) => (
    <Typography variant="caption4" color="vizualization-red-200">
        {error} {supportingText}
    </Typography>
);

const renderPayload = (
    formData: CustomActionDto,
    handleFieldChange: (id: string, value: string) => void,
    errors: string[],
) => {
    return (
        <div>
            <div>
                {renderDropdown(
                    ACTION_LABELS[1],
                    PAYLOAD_OPTIONS,
                    formData.meta?.request?.payload?.format ?? '',
                    (value) => handleFieldChange(CUSTOM_ACTION_LABELS[0], value),
                )}
                {errors.includes(`${ACTION_LABELS[1]} ${SUPPORTING_TEXT}`) && renderError(`${ACTION_LABELS[1]}`)}
            </div>
            <div>
                {formData.meta?.request?.payload?.value !== CUSTOM_ACTION_LABELS[1] &&
                    renderTextAreaField(
                        CUSTOM_ACTION_LABELS[9],
                        formData.meta?.request?.payload?.value ?? '',
                        (event) => {
                            handleFieldChange(CUSTOM_ACTION_LABELS[3], event.target.value);
                        },
                        true,
                    )}
                {errors.includes(`${ACTION_LABELS[8]} ${ENTER_VALID_FORMAT}`) &&
                    renderJSONError(`${ACTION_LABELS[8]}`, ENTER_VALID_FORMAT)}
                {errors.includes(`${ACTION_LABELS[8]} ${SUPPORTING_TEXT}`) && renderError(`${ACTION_LABELS[8]}`)}
            </div>
        </div>
    );
};

const renderError = (error: string) => (
    <Typography variant="caption4" color="vizualization-red-200">
        {error} {SUPPORTING_TEXT}
    </Typography>
);

export const GeneralInformationStep = ({
    formData,
    handleFieldChange,
    errors,
}: {
    formData: CustomActionDto;
    handleFieldChange: (id: string, value: string) => void;
    errors: string[];
}) => {
    const { t } = useI18n();
    return (
        <div className="action-block d-flex flex-column gap-3">
            <div className="d-flex flex-column gap-2">
                {renderInputField(
                    ACTION_LABELS[2],
                    formData.displayValue,
                    (event) => handleFieldChange(CUSTOM_ACTION_LABELS[4], event.target.value),
                    false,
                    t(CustomActionsLabelMap[ACTION_LABELS[2]]),
                )}
                {errors.includes(`${ACTION_LABELS[2]} ${SUPPORTING_TEXT}`) && renderError(`${ACTION_LABELS[2]}`)}
            </div>
            <div className="d-flex flex-column gap-2">
                {renderTextAreaField(
                    ACTION_LABELS[5],
                    formData.description ?? '',
                    (event) => handleFieldChange(CUSTOM_ACTION_LABELS[5], event.target.value),
                    false,
                    200,
                )}
                {errors.includes(`${ACTION_LABELS[5]} ${SUPPORTING_TEXT}`) && renderError(`${ACTION_LABELS[5]}`)}
            </div>
        </div>
    );
};

const renderURLError = (error: string) => (
    <Typography variant="caption4" color="vizualization-red-200">
        {error} {INVALID_URL_TEXT}
    </Typography>
);

export const RequestConfigurationStep = ({
    formData,
    handleFieldChange,
    errors,
}: {
    formData: CustomActionDto;
    handleFieldChange: (id: string, value: string) => void;
    errors: string[];
}) => {
    const url = formData.meta?.request?.url || '';
    const { t } = useI18n();
    return (
        <div className="action-block d-flex flex-column gap-3 custom-scrollbar">
            <div className="d-flex flex-column gap-2">
                {renderInputField(
                    ACTION_LABELS[3],
                    url,
                    (event) => {
                        const newUrl = event.target.value;
                        handleFieldChange(CUSTOM_ACTION_LABELS[2], newUrl);
                    },
                    false,
                    t(CustomActionsLabelMap[ACTION_LABELS[3]]),
                )}
                {errors.includes(`${ACTION_LABELS[3]} ${INVALID_URL_TEXT}`) && renderURLError(`${ACTION_LABELS[3]}`)}
                {errors.includes(`${ACTION_LABELS[3]} ${SUPPORTING_TEXT}`) && renderError(`${ACTION_LABELS[3]}`)}
            </div>

            <div>
                {renderDropdown(ACTION_LABELS[4], METHOD_OPTIONS, formData.meta?.request?.method, (value) =>
                    handleFieldChange(CUSTOM_ACTION_LABELS[6], value),
                )}
                {errors.includes(`${ACTION_LABELS[4]} ${SUPPORTING_TEXT}`) && renderError(`${ACTION_LABELS[4]}`)}
            </div>
            {renderPayload(formData, handleFieldChange, errors)}
        </div>
    );
};

export const AdvancedSettingsStep = ({
    formData,
    handleAddRow,
    handleDeleteRow,
    handleTableChange,
}: {
    formData: CustomActionDto;
    handleAddRow: (dataKey: 'headers' | 'parameters') => () => void;
    handleDeleteRow: (dataKey: 'headers' | 'parameters') => (index: number) => () => void;
    handleTableChange: (
        dataKey: 'headers' | 'parameters',
        index: number,
        field: string,
    ) => (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) => {
    const { t } = useI18n();
    return (
        <div className="action-block d-flex flex-column gap-3 custom-scrollbar">
            <TableForm
                label={ACTION_LABELS[6]}
                headers={ACTION_TABLE_HEADERS}
                data={formData.meta?.request?.headers ?? {}}
                handleAdd={handleAddRow('headers')}
                handleDelete={handleDeleteRow('headers')}
                handleChange={(index: number, field: string) => handleTableChange('headers', index, field)}
                renderInputField={(
                    value: string,
                    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
                    isTableRow: boolean,
                ) =>
                    renderInputField(
                        CUSTOM_ACTION_LABELS[9],
                        value,
                        onChange,
                        isTableRow,
                        t(CustomActionsLabelMap[ACTION_LABELS[9]]),
                    )
                }
                buttonLabel={ADD_HEADER}
                tooltipLabel={t(CustomActionsLabelMap[ACTION_LABELS[6]])}
            />
            <TableForm
                label={ACTION_LABELS[7]}
                headers={ACTION_TABLE_HEADERS}
                data={formData.meta?.request?.params ?? {}}
                handleAdd={handleAddRow('parameters')}
                handleDelete={handleDeleteRow('parameters')}
                handleChange={(index: number, field: string) => handleTableChange('parameters', index, field)}
                renderInputField={(
                    value: string,
                    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
                    isTableRow: boolean,
                ) =>
                    renderInputField(
                        CUSTOM_ACTION_LABELS[9],
                        value,
                        onChange,
                        isTableRow,
                        t(CustomActionsLabelMap[ACTION_LABELS[9]]),
                    )
                }
                buttonLabel={ADD_PARAMETER}
                tooltipLabel={t(CustomActionsLabelMap[ACTION_LABELS[7]])}
            />
        </div>
    );
};
