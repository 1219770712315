import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ImPencil } from '@react-icons/all-files/im/ImPencil';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Canvas, EngagementStudioSidePanel } from 'Components/molecules';
import { ExpandableEngagementList } from 'Components/organisms';
import {
    CUSTOM_MESSAGE_ID,
    INCLUDE_MANAGER_ID,
    HELP_ID,
    MESSAGE_TONE_ID,
    MOCK_DATA,
    MockDataType,
    REMINDER_ID,
    RESOLUTION_DEADLINE_ID,
    RESOLUTION_EXPIRY_ACTION_ID,
    RESOLVE_NOW_ID,
    ACTION_BUTTON_ID,
    REWARDS_ID,
    ReminderProps,
    ResolutionDeadlineProps,
    RewardsProps,
    SCHEDULE_RESOLUTION_ID,
    TRIAGE_ID,
    ADDITIONAL_CONTEXT_ID,
    INSTRUCTION_ID,
    MARK_COMPLETE_ID,
    ESCALATION_ID,
    LAUNCH_ACTION_ID,
    DELAY_ENGAGEMENT_ID,
    DelayEngagementProps,
    TRAINING_ID,
    REDIRECT_ENGAGEMENT_ID,
} from 'Components/molecules/EngagementStudioSidePanel/MockData';
import { PanelItem } from 'Components/molecules/EngagementStudioSidePanel/EngagementStudioSidePanel';
import { MdOutlineError } from '@react-icons/all-files/md/MdOutlineError';
import { CanvasType, MenuItem, Row } from 'Core-utils/types';
import { Message } from 'Components/organisms/PreviewEngagement/PreviewEngagement';
import {
    DEFAULT_ERROR_MSG,
    DO_NOTHING_ID,
    DO_NOTHING_TEXT,
    ENGAGEMENT_TITLE,
    REWARDS_HEADING,
    SAVE_TEXT,
    fieldNameMap,
} from 'Core-utils/constants/constants';
import { ACTIVE_WORKFLOWS_ROUTE } from 'Core-utils/routes';
import { PreviewEngagement } from 'Components/organisms/PreviewEngagement';
import MockMessageData from './MockMessageData';
import classNames from 'classnames';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import {
    fetchServiceProfiles,
    getActionsSummary,
    getEngagementFlow,
    getFindingSpecs,
    updateEngagementFlow,
} from 'Apis/library';
import PageErrorHandlerAndLoaderWrapper from 'Wrappers/PageErrorHandlerAndLoaderWrapper';
import {
    _FlowActions,
    CohortKind,
    CustomerActionSummaryDto,
    EMAIL_CID,
    EngagementRedirectionKind,
    FindingSpecDto,
    FindingStatus,
    FlowActionButton,
    FlowSpecDto,
    FlowSpecFilter,
    FlowSpecUpsertDto,
    KIND,
    ServiceProfile,
} from '@ampsec/platform-client';
import _, { isArray } from 'lodash';
import { getEngagementChannelOptions } from 'Components/organisms/EngagementChannelExpandable/EngagementChannelExpandable';
import { ToastsContext } from 'Rdx/contexts/ToastsContext';
import { IoArrowBackOutline } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { useProviderData } from 'Hooks/useProviderData';
import AmplifierLogo from 'Assets/icons/AmpSec.svg';
import { getServiceInfoByKey, sortFindingSpecsData } from 'Core-utils/helpers/helpers';
import { useConnectorData } from 'Hooks/useConnectorsData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from 'Rdx/store';
import { FindingSpecsWithProviderName } from 'Rdx/models/findingSpecsModel';
import { ALL_USERS_COHORT } from 'Components/organisms/UserCohort/Data';
import './styles.scss';
import useTimeSpent from 'Hooks/useTimeSpent';

const COMPONENT_IDENTIFIER = 'Create/Edit Engagement Page';

type PromptState = Record<string, Record<string, unknown>>;

const transformer = (dataItem: Awaited<ReturnType<typeof getEngagementFlow>>): FlowSpecDto => {
    if (!dataItem?.data.length) {
        return {} as FlowSpecDto;
    }
    return dataItem.data[0];
};

type ArrayElementType<T> = T extends (infer U)[] ? U : never;
type RewardsElementType = ArrayElementType<RewardsType>;

type FindingsType = FlowSpecUpsertDto['triggerFilter']['trigger'];

type UserCohortType = {
    id: string;
    kind: CohortKind;
    displayValue: string;
    value: string;
    icon?: string;
    inclusive: boolean;
};

type DurationUnit = 'minutes' | 'hours' | 'weeks' | 'days';

export type RewardsType = FlowSpecFilter['rewards'];

const handleBackClick = () => {
    history.back();
};

const timeMinuteConverter = (time: number, unit: DurationUnit, toMinutes = false) => {
    const conversionFactors: { [key in DurationUnit]: number } = {
        minutes: 1,
        hours: 60,
        days: 24 * 60,
        weeks: 7 * 24 * 60,
    };

    if (toMinutes) {
        return time / conversionFactors[unit];
    }
    return time * conversionFactors[unit];
};

const handleRewards = (value: Omit<RewardsProps, 'onOptionSelected' | 'onKeyDown'>): RewardsElementType => ({
    minutes: Number.isNaN(timeMinuteConverter(Number(value.time), value.unit as DurationUnit))
        ? 0
        : timeMinuteConverter(Number(value.time), value.unit as DurationUnit),
    isUpperBound: value.duration === 'after',
    points: Number.isNaN(Number(value.value)) ? 0 : Number(value.value),
    units: value.unit,
    value: Number.isNaN(Number(value.time)) ? 0 : Number(value.time),
});
const getTargetResolution = (value: Omit<ResolutionDeadlineProps, 'onOptionSelected'>) => ({
    minutes: timeMinuteConverter(Number(value.deadlineTime), value.deadlineUnit as DurationUnit),
    units: value.deadlineUnit,
    value: Number(value.deadlineTime),
});

const getReminderInterval = (value: Omit<ReminderProps, 'onOptionSelected'>) => ({
    minutes: timeMinuteConverter(Number(value.reminderTime), value.reminderUnit as DurationUnit),
    units: value.reminderUnit,
    value: Number(value.reminderTime),
});

const setReward = (
    data: { id: string } & ArrayElementType<FlowSpecDto['rewards']>,
): Omit<RewardsProps, 'onOptionSelected' | 'onKeyDown'> => {
    return {
        id: data.id,
        duration: data.isUpperBound ? 'after' : 'within',
        unit: data.units,
        time: (
            data.value ?? timeMinuteConverter(data.minutes, (data.units as DurationUnit) ?? 'minutes', true)
        ).toString(),
        value: data.points?.toString(),
    };
};

const getDelayEngagement = (value: Omit<DelayEngagementProps, 'onOptionSelected'>) => {
    return {
        minutes: timeMinuteConverter(Number(value.delayEngagementTime), value.delayEngagementUnit as DurationUnit),
        units: value.delayEngagementUnit,
        value: Number(value.delayEngagementTime),
    };
};
function findDuplicateActionLabels(data: FlowActionButton[]): string[] {
    const labelCount: { [key: string]: number } = {};
    const duplicateIds: string[] = [];

    data.forEach((button) => {
        const label = button.actionLabel;
        if (label) {
            labelCount[label] = (labelCount[label] || 0) + 1;
            if (labelCount[label] > 1) {
                duplicateIds.push(button.id);
            }
        }
    });

    return duplicateIds;
}

const isValidChannelUrl = (url: string) => {
    const regex = /https:\/\/teams\.microsoft\.com\/l\/channel\/19%3A[A-Za-z0-9_\-]+%40thread\.tacv2/;
    return regex.test(url);
};

const handleFilters = (
    values: PromptState,
    data: MockDataType[],
    checked: boolean,
    connectorProviderMap: {
        cid: string | undefined;
        providerDisplayValue: string | undefined;
        providerIcon: string | undefined;
    }[],
    currentEngagementOption: string,
): FlowSpecUpsertDto => {
    const filters: FlowSpecUpsertDto = {} as FlowSpecUpsertDto;
    for (const id in values) {
        if (_.startsWith(id, 'Rewards') && !_.isEmpty(values[id])) {
            const reward = handleRewards(values[id] as Omit<RewardsProps, 'onOptionSelected' | 'onKeyDown'>);
            if (filters.rewards) {
                filters.rewards.push(reward);
            } else {
                filters.rewards = [reward];
            }
        } else if (_.startsWith(id, 'ActionButton') && !_.isEmpty(values[id])) {
            const action = {
                id: id as string,
                actions: [
                    { actionId: values[id]?.actionId !== DO_NOTHING_ID ? (values[id]?.actionId as string) : null },
                ],
                findingStatus: (values[id]?.findingStatus as FindingStatus) ?? null,
                actionLabel: (values[id]?.actionLabel as string)?.trim(),
            };
            if (filters.actions?.actionButtons?.length === 0) {
                filters.actions = {
                    ...filters.actions,
                    actionButtons: [action],
                };
            } else {
                filters.actions = {
                    ...filters.actions,
                    actionButtons: [...(filters.actions?.actionButtons || []), action],
                };
            }
        } else {
            switch (id) {
                case MESSAGE_TONE_ID:
                    filters.tone = { kind: values[id].nature as string };
                    break;
                case SCHEDULE_RESOLUTION_ID:
                    filters.actions = {
                        ...filters.actions,
                        scheduleResolutionAction: {
                            actionId: values[id]?.actionId !== DO_NOTHING_ID ? (values[id]?.actionId as string) : null,
                            findingStatus: (values[id]?.findingStatus as FindingStatus) ?? null,
                        },
                    };
                    break;
                case RESOLUTION_DEADLINE_ID:
                    filters.targetResolution = getTargetResolution(
                        values[id] as Omit<ResolutionDeadlineProps, 'onOptionSelected'>,
                    );
                    break;
                case ESCALATION_ID:
                    const engagementChannelConnectorIds: string[] =
                        currentEngagementOption === 'Email (Non-interactive)'
                            ? [EMAIL_CID]
                            : [
                                  connectorProviderMap.find(
                                      (item) => item.providerDisplayValue === values[id]?.escalationChannel,
                                  )?.cid ?? '',
                              ];
                    filters.escalation = {
                        interval: {
                            minutes: timeMinuteConverter(
                                Number(values[id]?.escalationTime ?? 3),
                                (values[id]?.escalationUnit as DurationUnit) ?? 'days',
                            ),
                            units: (values[id]?.escalationUnit as DurationUnit) ?? 'days',
                            value: Number(values[id]?.escalationTime ?? 3),
                        },
                        engagementChannelConnectorIds: engagementChannelConnectorIds,
                    };
                    break;
                case REMINDER_ID:
                    filters.reminderInterval = getReminderInterval(
                        values[id] as Omit<ReminderProps, 'onOptionSelected'>,
                    );
                    break;
                case DELAY_ENGAGEMENT_ID:
                    filters.delayEngagement = getDelayEngagement(
                        values[id] as Omit<DelayEngagementProps, 'onOptionSelected'>,
                    );
                    break;
                case TRAINING_ID:
                    filters.training = {
                        subject: values[id]?.subject as string,
                        context: values[id]?.context as string,
                    };
                    break;
                case REDIRECT_ENGAGEMENT_ID:
                    filters.redirection = {
                        kind: values[id]?.redirectionKind as EngagementRedirectionKind,
                        channelId: values[id]?.channelId as string,
                        includeUser: values[id]?.includeEmployee !== 'omit',
                    };
                    break;
                case HELP_ID:
                    // TODO: No field in the FlowSpecUpsertDto for Help
                    break;
                case RESOLUTION_EXPIRY_ACTION_ID:
                    filters.actions = {
                        ...filters.actions,
                        expirationAction: {
                            actionId: values[id]?.actionId !== DO_NOTHING_ID ? (values[id]?.actionId as string) : null,
                            findingStatus: (values[id]?.findingStatus as FindingStatus) ?? null,
                        },
                    };
                    break;
                case RESOLVE_NOW_ID:
                    filters.actions = {
                        ...filters.actions,
                        resolveNowAction: {
                            actionId: values[id]?.actionId !== DO_NOTHING_ID ? (values[id]?.actionId as string) : null,
                            findingStatus: (values[id]?.findingStatus as FindingStatus) ?? null,
                        },
                    };
                    break;
                case TRIAGE_ID:
                    filters.triage = {
                        ...filters.triage,

                        yesAction: {
                            actionId:
                                values[id]?.yesActionId !== DO_NOTHING_ID ? (values[id]?.yesActionId as string) : null,
                            findingStatus: (values[id]?.yesActionStatus as FindingStatus) ?? null,
                            responseMessage: values[id]?.yesResponseMessage as string,
                        },
                        noAction: {
                            actionId:
                                values[id]?.noActionId !== DO_NOTHING_ID ? (values[id]?.noActionId as string) : null,
                            findingStatus: (values[id]?.noActionStatus as FindingStatus) ?? null,
                            responseMessage: values[id]?.noResponseMessage as string,
                        },
                        message: values[id]?.triageMessage as string,
                    };
                    break;
                case CUSTOM_MESSAGE_ID:
                    filters.customMessage = {
                        append: checked,
                        template: values[id]?.template as string,
                    };
                    break;
                case ADDITIONAL_CONTEXT_ID:
                    filters.additionalContext = {
                        ...filters.additionalContext,
                        data: values[id]?.data as string,
                    };
                    break;
                case INSTRUCTION_ID:
                    filters.actions = {
                        ...filters.actions,
                        includeInstructions: true,
                    };
                    break;
                case MARK_COMPLETE_ID:
                    filters.actions = {
                        ...filters.actions,
                        includeMarkComplete: true,
                    };
                    break;
                case LAUNCH_ACTION_ID:
                    filters.actions = {
                        ...filters.actions,
                        launchAction: {
                            actionId: values[id]?.actionId !== DO_NOTHING_ID ? (values[id]?.actionId as string) : null,
                            findingStatus: (values[id]?.findingStatus as FindingStatus) ?? null,
                        },
                    };
                    break;
                default:
                    break;
            }
        }
    }

    return filters;
};

const getCanvasItem = (id: string) => {
    let filteredItem = MOCK_DATA.find((item) => item.id === id);
    if (filteredItem?.name === REWARDS_HEADING) {
        filteredItem = {
            ...filteredItem,
            id: 'Rewards' + Math.floor(Math.random() * 900000) + 100000,
        };
    } else if (filteredItem?.name === 'Action Button') {
        filteredItem = {
            ...filteredItem,
            id: 'ActionButton' + Math.floor(Math.random() * 900000) + 100000,
        };
    }
    return filteredItem;
};

const CreateEngagementPage = () => {
    const { id } = useParams();
    useTimeSpent(COMPONENT_IDENTIFIER, { engagementFlowId: id });
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);
    const [title, setTitle] = useState<string>('');
    const [data, setData] = useState<MockDataType[]>([]);
    const [promptItems, setPromptItems] = useState<PanelItem[]>(MOCK_DATA);
    const [value, setValue] = useState<PromptState>({});
    const [checked, setChecked] = useState<boolean>(false);
    const [isPreviewMode, setPreviewMode] = useState<boolean>();
    const [draggable, setDraggable] = useState<boolean>(false);
    const [serviceProfiles, setServiceProfiles] = useState<ServiceProfile[]>([]);
    const { connectorProviderMap } = useProviderData();
    const [findingsDropdownSearchInput, setFindingsDropdownSearchInput] = useState<string>('');
    const [findingsDropdownItems, setFindingsDropdownItems] = useState<FindingSpecsWithProviderName[]>([]);
    const [validationError, setValidationError] = useState<Record<string, boolean>>({});
    const { connectorsByCategoryGroup } = useConnectorData();
    const dispatch = useDispatch<Dispatch>();
    const { findingSpecs } = useSelector((state: RootState) => state.findingSpecsModel);
    const isProdEnv = process.env.ENVIRONMENT === 'production';
    const isDevEnv = process.env.ENVIRONMENT === 'development';
    const mapFindingSpecItem = (item: FindingSpecDto) => {
        const provider = connectorProviderMap.find((mappedItem) => mappedItem.cid === item.cid);
        const providerIcon =
            item.cid === null
                ? AmplifierLogo
                : provider?.providerIcon ?? <MdOutlineError className="text-secondary-accent-red-2" size={20} />;
        return {
            id: item.id,
            displayValue: item.displayValue,
            value: item.displayValue,
            icon: providerIcon,
            kind: item.eventType,
            disabled: false,
            cid: item.cid,
            provider: provider?.providerDisplayValue,
            severity: item.severity,
        };
    };
    const { data: fetchFindings } = useApiObjectData<FindingSpecDto[], FindingSpecDto[]>(
        KIND.FINDING_SPECS,
        getFindingSpecs,
        (a) => a,
        {
            limit: 350,
        },
    );

    const { data: actionsData } = useApiObjectData<CustomerActionSummaryDto[], CustomerActionSummaryDto[]>(
        KIND.CUSTOM_ACTIONS,
        getActionsSummary,
        (a) => a,
        { isTemplate: false },
    );

    const containerClasses = classNames(
        'create-engagement-page__body__left w-100 d-flex flex-column overflow-hidden position-relative',
        {
            'preview-mode': isPreviewMode,
        },
    );
    const { addToast } = useContext(ToastsContext);

    const { data: apiData, isLoading: isAPIDataLoading, error } = useApiObjectData('', getEngagementFlow, transformer, {
        id,
    });

    if (error) {
        addToast({
            severity: 'error',
            body: DEFAULT_ERROR_MSG,
        });
    }

    const [selectedFindingsOptions, setSelectedFindingsOptions] = useState<
        { id: string; value: string; cid: string; eventType: string; icon: string }[]
    >([]);

    const [rows, setRows] = useState<Row[]>([]);

    const [selectedEngagementOptions, setSelectedEngagementOptions] = useState<
        {
            id: string;
            icon: string;
            value: string;
        }[]
    >([]);

    const [isEscalationPillDragged, setIsEscalationPillDragged] = useState<boolean>(false);
    const { dropdownItems } = getEngagementChannelOptions();
    const dropdownEngagementOptions = dropdownItems.filter(
        (item) => !selectedEngagementOptions.map((option) => option?.id)?.includes(item?.id || ''),
    );

    const spanRef = useRef<HTMLSpanElement>(null);
    const [inputWidth, setInputWidth] = useState(0);

    useEffect(() => {
        if (spanRef.current) {
            setInputWidth(spanRef.current.offsetWidth + 10);
        }
    }, [title]);

    const currentEngagementOption = useMemo(
        () =>
            dropdownItems
                // eslint-disable-next-line max-nested-callbacks
                .filter((item) => !selectedEngagementOptions.map((option) => option?.id)?.includes(item?.id || ''))
                ?.map((mappedItem) => mappedItem.value)
                .filter((value): value is string => value !== undefined)[0],
        [selectedEngagementOptions],
    );

    const escalationItemIndex = promptItems.findIndex((item) => item.id === ESCALATION_ID);

    useEffect(() => {
        if (promptItems.find((item) => item.id === TRAINING_ID)?.disabled) {
            setValue((prevValue) => {
                return {
                    ...prevValue,
                    [TRAINING_ID]: {
                        // eslint-disable-next-line max-nested-callbacks
                        subject: selectedFindingsOptions.map((option) => _.startCase(option.eventType)).join(', '),
                        context: prevValue[TRAINING_ID]?.context,
                    },
                };
            });
        }
    }, [selectedFindingsOptions]);

    useEffect(() => {
        if (promptItems[escalationItemIndex].disabled && dropdownEngagementOptions.length !== 0) {
            setValue((prevValue) => {
                return {
                    ...prevValue,
                    [ESCALATION_ID]: {
                        escalationTime: apiData?.escalation?.interval?.value ?? '3',
                        escalationUnit: apiData?.escalation?.interval?.units ?? 'days',
                        escalationChannel: currentEngagementOption,
                    },
                };
            });
        } else {
            setValue((prevValue) => {
                const tempValue = { ...prevValue };
                delete tempValue[ESCALATION_ID];
                return tempValue;
            });
        }
    }, [selectedEngagementOptions, dropdownItems.length, promptItems[escalationItemIndex].disabled]);
    const sortByDescendingValue = (a?: MockDataType, b?: MockDataType) => {
        if (!a || !b) {
            return 0;
        }
        const aValue = Number((value[a.id] && value[a.id]?.value) ?? '');
        const bValue = Number((value[b.id] && value[b.id]?.value) ?? '');
        return bValue - aValue;
    };

    useEffect(() => {
        fetchServiceProfiles().then((data: ServiceProfile[]) => setServiceProfiles(data));
    }, []);

    useEffect(() => {
        const handleClickOutside = () => {
            if (containerRef.current) {
                const sortedData = [...data].sort(sortByDescendingValue);
                setData(sortedData);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [data, containerRef, sortByDescendingValue]);

    const engagementOptions = getEngagementChannelOptions().dropdownItems;

    const disablePromptWithId = (id: string) => {
        setPromptItems((items) => {
            return items.map((item) => {
                const tempItem = { ...item };
                if (tempItem.id === id && tempItem.id !== REWARDS_ID && tempItem.id !== ACTION_BUTTON_ID) {
                    tempItem.disabled = true;
                }

                return tempItem;
            });
        });
    };

    useEffect(() => {
        const isItemDisabled = (item: FindingSpecDto) =>
            selectedFindingsOptions.some((selectedOption) => selectedOption.id === item.id);

        const updatedFindingsDropdownItems = Object.values(fetchFindings).map((findingSpec: FindingSpecDto) => {
            const providerDisplayValue =
                connectorProviderMap.find((item) => item.cid === findingSpec.cid)?.providerDisplayValue ?? '';
            return {
                ...mapFindingSpecItem(findingSpec),
                disabled: isItemDisabled(findingSpec),
                providerDisplayValue,
            };
        });

        const sortedFindingSpecsData = sortFindingSpecsData(updatedFindingsDropdownItems);
        dispatch.findingSpecsModel.setFindings(sortedFindingSpecsData);

        setFindingsDropdownItems(sortedFindingSpecsData);
    }, [fetchFindings, selectedFindingsOptions]);

    const ALLOWED_IDS = [
        CUSTOM_MESSAGE_ID,
        SCHEDULE_RESOLUTION_ID,
        REMINDER_ID,
        HELP_ID,
        RESOLVE_NOW_ID,
        ACTION_BUTTON_ID,
        INCLUDE_MANAGER_ID,
        INSTRUCTION_ID,
        MARK_COMPLETE_ID,
        DELAY_ENGAGEMENT_ID,
    ];
    const updatePrompts = (items: PanelItem[]) => {
        const hasTriageId = data.some((item) => item.id === TRIAGE_ID);
        const hasAllowedId = data.some((item) => ALLOWED_IDS.includes(item.id));
        if (hasTriageId) {
            items.forEach((prompt) => {
                if (ALLOWED_IDS.includes(prompt.id)) {
                    prompt.isDraggable = false;
                    prompt.disabled = true;
                }
            });
        }
        if (hasAllowedId) {
            items.forEach((prompt) => {
                if (TRIAGE_ID === prompt.id) {
                    prompt.isDraggable = false;
                    prompt.disabled = true;
                }
            });
        }

        return items;
    };

    useEffect(() => {
        setPromptItems((prevPrompts) => {
            const updatedPrompts = [...prevPrompts];
            updatePrompts(updatedPrompts);
            return updatedPrompts;
        });
    }, [data]);

    useEffect(() => {
        setPromptItems(MOCK_DATA);
        if (!apiData.id) {
            return;
        }
        if (isProdEnv) {
            setPromptItems(
                MOCK_DATA.filter(
                    (item) =>
                        item.id !== INCLUDE_MANAGER_ID &&
                        item.id !== REWARDS_ID &&
                        item.id !== HELP_ID &&
                        item.id !== TRAINING_ID,
                ),
            );
        } else if (isDevEnv) {
            setPromptItems(
                MOCK_DATA.filter(
                    (item) => item.id !== INCLUDE_MANAGER_ID && item.id !== REWARDS_ID && item.id !== HELP_ID,
                ),
            );
        } else {
            setPromptItems(MOCK_DATA);
        }
        setTitle(apiData.name);
        const filteredItems: MockDataType[] = [];
        let values: PromptState = {};

        const loadPrompts = (id: string, promptValues: Record<string, unknown>) => {
            const filteredItem = getCanvasItem(id);
            filteredItems.push(filteredItem as MockDataType);
            values = {
                ...values,
                [id]: { id, ...promptValues },
            };
            disablePromptWithId(id);
        };

        if (!isProdEnv && !isDevEnv && apiData.rewards) {
            let rewards = {};
            for (const reward of apiData.rewards) {
                const filteredItem = getCanvasItem(REWARDS_ID);
                filteredItems.push(filteredItem as MockDataType);
                const newReward = setReward({ id: filteredItem?.id ?? '0', ...reward });
                rewards = { ...rewards, [newReward.id]: newReward };
                setData((prevData) => [...prevData, filteredItem as MockDataType]);
            }
            values = rewards;
        }

        if (apiData.tone) {
            loadPrompts(MESSAGE_TONE_ID, { nature: apiData.tone.kind });
        }

        if (apiData?.actions?.scheduleResolutionAction) {
            loadPrompts(SCHEDULE_RESOLUTION_ID, {
                actionId:
                    apiData.actions.scheduleResolutionAction.actionId !== null
                        ? apiData.actions.scheduleResolutionAction.actionId
                        : DO_NOTHING_ID,
                findingStatus: apiData.actions.scheduleResolutionAction.findingStatus,
            });
        }

        if (isDevEnv && apiData?.training) {
            loadPrompts(TRAINING_ID, {
                subject: apiData.training.subject,
                context: apiData.training.context,
            });
        }

        if (apiData?.redirection) {
            loadPrompts(REDIRECT_ENGAGEMENT_ID, {
                redirectionKind: apiData.redirection.kind,
                channelId: apiData.redirection.channelId,
                includeEmployee: apiData.redirection.includeUser ? 'include' : 'omit',
            });
        }
        if (!isProdEnv && !isDevEnv && apiData.scheduleConfig?.required) {
            const filteredItem = getCanvasItem(INCLUDE_MANAGER_ID);
            filteredItems.push(filteredItem as MockDataType);
            disablePromptWithId(INCLUDE_MANAGER_ID);
        }

        if (!isProdEnv && !isDevEnv && apiData.scheduleConfig?.required) {
            const filteredItem = getCanvasItem(HELP_ID);
            filteredItems.push(filteredItem as MockDataType);
            disablePromptWithId(HELP_ID);
        }

        if (apiData.targetResolution) {
            loadPrompts(RESOLUTION_DEADLINE_ID, {
                deadlineTime: apiData.targetResolution.value,
                deadlineUnit: apiData.targetResolution.units,
            });
        }

        if (apiData.reminderInterval) {
            loadPrompts(REMINDER_ID, {
                reminderTime: apiData.reminderInterval.value,
                reminderUnit: apiData.reminderInterval.units,
            });
        }

        if (apiData?.actions?.expirationAction) {
            loadPrompts(RESOLUTION_EXPIRY_ACTION_ID, {
                actionId:
                    apiData.actions.expirationAction.actionId !== null
                        ? apiData.actions.expirationAction.actionId
                        : DO_NOTHING_ID,
                findingStatus: apiData.actions.expirationAction.findingStatus,
            });
        }

        if (apiData?.actions?.resolveNowAction) {
            loadPrompts(RESOLVE_NOW_ID, {
                actionId:
                    apiData.actions.resolveNowAction.actionId !== null
                        ? apiData.actions.resolveNowAction.actionId
                        : DO_NOTHING_ID,
                findingStatus: apiData.actions.resolveNowAction.findingStatus,
            });
        }

        if (apiData?.actions?.actionButtons) {
            let actions = {};
            if (isArray(apiData.actions.actionButtons)) {
                for (const action of apiData?.actions?.actionButtons) {
                    const filteredItem = getCanvasItem(ACTION_BUTTON_ID);
                    filteredItems.push(filteredItem as MockDataType);
                    const newAction = {
                        id: filteredItem?.id ?? '0',
                        actionId: action.actions[0].actionId !== null ? action.actions[0].actionId : DO_NOTHING_ID,
                        findingStatus: action.findingStatus,
                        actionLabel: action.actionLabel,
                    };
                    actions = { ...actions, [newAction.id]: newAction };
                    setData((prevData) => [...prevData, filteredItem as MockDataType]);
                }
            }
            values = { ...values, ...actions };
        }

        if (apiData?.actions?.launchAction) {
            loadPrompts(LAUNCH_ACTION_ID, {
                actionId:
                    apiData.actions.launchAction.actionId !== null
                        ? apiData.actions.launchAction.actionId
                        : DO_NOTHING_ID,
                findingStatus: apiData.actions.launchAction.findingStatus,
            });
        }

        if (apiData?.delayEngagement) {
            loadPrompts(DELAY_ENGAGEMENT_ID, {
                delayEngagementTime: apiData.delayEngagement.value,
                delayEngagementUnit: apiData.delayEngagement.units,
            });
        }

        if (apiData?.triage?.yesAction && apiData?.triage?.noAction) {
            loadPrompts(TRIAGE_ID, {
                yesActionId:
                    apiData.triage.yesAction.actionId !== null ? apiData.triage.yesAction.actionId : DO_NOTHING_ID,
                yesActionStatus: apiData.triage.yesAction.findingStatus,
                yesResponseMessage: apiData?.triage?.yesAction?.responseMessage,
                noActionId:
                    apiData.triage.noAction.actionId !== null ? apiData.triage.noAction.actionId : DO_NOTHING_ID,
                noActionStatus: apiData.triage.noAction.findingStatus,
                noResponseMessage: apiData?.triage?.noAction?.responseMessage,
                triageMessage: apiData.triage.message,
            });
        }

        if (apiData.customMessage) {
            loadPrompts(CUSTOM_MESSAGE_ID, {
                template: apiData.customMessage.template,
                append: apiData.customMessage.append,
            });
            setChecked(apiData.customMessage.append ?? false);
        }

        if (apiData.additionalContext) {
            loadPrompts(ADDITIONAL_CONTEXT_ID, {
                data: apiData.additionalContext.data,
            });
        }

        if (apiData.actions?.includeInstructions) {
            loadPrompts(INSTRUCTION_ID, { includeInstructions: apiData.actions.includeInstructions });
        }

        if (apiData.escalation) {
            const engagementChannel =
                apiData.escalation?.engagementChannelConnectorIds[0] === EMAIL_CID
                    ? 'Email (Non-interactive)'
                    : connectorProviderMap.find(
                          (mappedItem) => mappedItem.cid === apiData.escalation?.engagementChannelConnectorIds[0],
                      )?.providerDisplayValue;
            loadPrompts(ESCALATION_ID, {
                escalationTime: apiData.escalation.interval.value,
                escalationUnit: apiData.escalation.interval.units,
                escalationChannel: engagementChannel,
            });
        }

        if (apiData.actions?.includeMarkComplete) {
            loadPrompts(MARK_COMPLETE_ID, { includeMarkComplete: apiData.actions.includeMarkComplete });
        }

        if (apiData.triggerFilter.trigger?.length) {
            const newFindings = apiData.triggerFilter.trigger
                .map((data) => {
                    const provider = connectorProviderMap.find((mappedItem) => mappedItem.cid === data.cid);
                    const providerIcon = !data.cid
                        ? AmplifierLogo
                        : provider?.providerIcon ?? (
                              <MdOutlineError className="text-secondary-accent-red-2" size={20} />
                          );
                    return {
                        id: data?.id ?? '',
                        icon: providerIcon,
                        value: data?.displayValue || '',
                        cid: data?.cid || '',
                        eventType: data?.eventType || '',
                    };
                })
                .filter((item) => !!item.id);
            setSelectedFindingsOptions(newFindings);
        }

        if (apiData.filter.cohorts?.length) {
            const newRows = apiData.filter.cohorts.reduce(
                (
                    prev: {
                        id: number;
                        selectedValue: string;
                        included: boolean;
                        autoCompleteData: UserCohortType[];
                    }[],
                    curr: any,
                ) => {
                    const temp = [...prev];
                    if (curr?.inclusive) {
                        temp[0].autoCompleteData.push({
                            ...curr,
                            icon: connectorsByCategoryGroup?.['Identity & Access']?.[0]?.imageUrl,
                        });
                    } else {
                        temp[1].autoCompleteData.push({
                            ...curr,
                            icon: connectorsByCategoryGroup?.['Identity & Access']?.[0]?.imageUrl,
                        });
                    }
                    return temp;
                },
                [
                    {
                        id: 1,
                        selectedValue: 'Include',
                        included: true,
                        autoCompleteData: [],
                    },
                    {
                        id: 2,
                        selectedValue: 'Exclude',
                        included: false,
                        autoCompleteData: [],
                    },
                ],
            );
            setRows(newRows.filter((row) => row.autoCompleteData.length));
        }
        if (apiData.engagementChannelCids.length) {
            const newEngagements = apiData.engagementChannelCids
                .map((id) => {
                    const option = engagementOptions.find((item) => item.id === id);
                    return { id: option?.id || '', icon: option?.icon || '', value: option?.value || '' };
                })
                .filter((item) => !!item.id);
            setSelectedEngagementOptions(newEngagements);
        }
        setData(filteredItems);
        setValue(values);
    }, [apiData]);

    useEffect(() => {
        if (rows.length === 0 || (rows[0]?.autoCompleteData?.length ?? 0) === 0) {
            const imageUrl = connectorsByCategoryGroup?.['Identity & Access']?.[0]?.imageUrl;
            setRows(ALL_USERS_COHORT(imageUrl));
        }
    }, [rows]);

    const handleChecked = () => {
        setChecked((checked) => !checked);
    };

    const handleValueChange = (option: string | string[], fieldName: string, id: string, selectedId?: string) => {
        setValue((prevValue) => {
            const tempValue = prevValue[id] ?? {};
            tempValue[fieldName] = option;
            if (id === TRIAGE_ID && (fieldName === 'yesActionId' || fieldName === 'noActionId')) {
                return { ...prevValue, [id]: { ...tempValue, [fieldName]: selectedId } };
            }
            return fieldName === 'actionId'
                ? { ...prevValue, [id]: { ...tempValue, actionId: selectedId } }
                : { ...prevValue, [id]: tempValue };
        });
        setValidationError((prevValues) => ({ ...prevValues, [id]: false }));
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            const sortedData = [...data].sort(sortByDescendingValue);
            setData(sortedData);
        }
    };

    const handleDragStop = (id: string, _event: any, newPosition: { x: number; y: number }) => {
        const isWithinDropZone = newPosition.x < -100;

        const filteredItem = getCanvasItem(id);
        if (isWithinDropZone) {
            setData((prevData) => {
                const newData = [...prevData, filteredItem];
                return newData as MockDataType[];
            });
            disablePromptWithId(id);
        }
        setValue((prevValue) => {
            const tempValue = prevValue[id] ?? {};
            const fieldName = fieldNameMap[filteredItem?.name ?? ''];
            if (fieldName === 'nature') {
                tempValue[fieldName] = 'Friendly';
            } else if (fieldName !== undefined) {
                tempValue[fieldName as string] = DO_NOTHING_TEXT;
            }
            if (id === RESOLUTION_DEADLINE_ID && (fieldName[0] === 'deadlineTime' || fieldName[1] === 'deadlineUnit')) {
                return {
                    ...prevValue,
                    [id]: {
                        ...tempValue,
                        [fieldName[0]]: '3',
                        [fieldName[1]]: 'days',
                    },
                };
            }
            if (id === REMINDER_ID && (fieldName[0] === 'reminderTime' || fieldName[1] === 'reminderUnit')) {
                return {
                    ...prevValue,
                    [id]: {
                        ...tempValue,
                        [fieldName[0]]: '1',
                        [fieldName[1]]: 'days',
                    },
                };
            }
            if (
                id === DELAY_ENGAGEMENT_ID &&
                (fieldName?.[0] === 'delayEngagementTime' || fieldName?.[1] === 'delayEngagementUnit')
            ) {
                return {
                    ...prevValue,
                    [id]: {
                        ...tempValue,
                        [fieldName[0]]: '1',
                        [fieldName[1]]: 'days',
                    },
                };
            }
            if (id === TRAINING_ID && (fieldName[0] === 'subject' || fieldName[1] === 'context')) {
                return {
                    ...prevValue,
                    [id]: {
                        ...tempValue,
                        [fieldName[0]]: selectedFindingsOptions
                            .map((option) => _.startCase(option.eventType))
                            .join(', '),
                        [fieldName[1]]: '',
                    },
                };
            }
            if (id === TRIAGE_ID && (fieldName[0] === 'yesActionId' || fieldName[1] === 'noActionId')) {
                return {
                    ...prevValue,
                    [id]: {
                        ...tempValue,
                        [fieldName[0]]: DO_NOTHING_ID,
                        [fieldName[1]]: DO_NOTHING_ID,
                    },
                };
            }
            return fieldName === 'actionId'
                ? { ...prevValue, [id]: { ...tempValue, actionId: DO_NOTHING_ID } }
                : { ...prevValue, [id]: tempValue };
        });
        setDraggable(false);
    };

    const handleDrag = () => {
        setDraggable(true);
    };

    const rightPanelRenderer = () => {
        return isPreviewMode ? (
            <PreviewEngagement messages={MockMessageData as Message[]} />
        ) : (
            <EngagementStudioSidePanel onItemDragStop={handleDragStop} panelItems={promptItems} onDrag={handleDrag} />
        );
    };

    const handleDisablePrompts = (items: PanelItem[], id: string) => {
        const tempItems = items.map((prompt) => {
            const tempItem = { ...prompt };
            if (prompt.id === id) {
                tempItem.disabled = false;
            }
            return tempItem;
        });
        return tempItems;
    };

    const handleEnablePrompts = (items: PanelItem[], promptIds: string[]) => {
        const tempItems = items.map((prompt) => {
            const tempItem = { ...prompt };
            if (promptIds.includes(prompt.id)) {
                tempItem.isDraggable = true;
                tempItem.disabled = false;
            }
            return tempItem;
        });
        return tempItems;
    };

    const handleDeleteClick = (id: string) => () => {
        const updatedData = data.filter((item) => item.id !== id);
        setData(updatedData);
        setValue((prevValue) => {
            const tempValue = { ...prevValue };
            delete tempValue[id];
            return tempValue;
        });
        if (id === ESCALATION_ID) {
            setIsEscalationPillDragged(false);
        }
        setPromptItems((items) => {
            let updatedItems = handleDisablePrompts(items, id);
            if (id === TRIAGE_ID) {
                updatedItems = handleEnablePrompts(updatedItems, ALLOWED_IDS);
            }
            if (ALLOWED_IDS.includes(id)) {
                updatedItems = handleEnablePrompts(updatedItems, [TRIAGE_ID]);
            }
            return updatedItems;
        });
        setValidationError((prevValues) => ({ ...prevValues, [id]: false }));
    };

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
    };

    useEffect(() => {
        if (apiData.escalation) {
            setPromptItems((items) => {
                // eslint-disable-next-line max-nested-callbacks
                return items.map((item) => {
                    const tempItem = { ...item };
                    if (tempItem.id === ESCALATION_ID) {
                        tempItem.disabled = true;
                    }
                    return tempItem;
                });
            });
        }
        setIsEscalationPillDragged(true);
    }, [isAPIDataLoading]);

    useEffect(() => {
        const escalateDropdownItems = dropdownItems
            // eslint-disable-next-line max-nested-callbacks
            .filter((item) => !selectedEngagementOptions.map((option) => option?.id)?.includes(item?.id || ''))
            ?.map((mappedItem) => mappedItem.value)
            .filter((value): value is string => value !== undefined);
        if (!isEscalationPillDragged) {
            if (dropdownItems.length <= 1 || dropdownEngagementOptions.length === 0) {
                setPromptItems((items) => {
                    // eslint-disable-next-line max-nested-callbacks
                    return items.map((item) => {
                        const tempItem = { ...item };
                        if (tempItem.id === ESCALATION_ID) {
                            tempItem.disabled = true;
                            tempItem.isDraggable = false;
                        }
                        return tempItem;
                    });
                });
            } else {
                setPromptItems((items) => {
                    // eslint-disable-next-line max-nested-callbacks
                    return items.map((item) => {
                        const tempItem = { ...item };
                        if (tempItem.id === ESCALATION_ID && !Object.keys(value).includes(ESCALATION_ID.toString())) {
                            tempItem.disabled = false;
                            tempItem.isDraggable = true;
                        }
                        return tempItem;
                    });
                });
            }
        }
        if (escalateDropdownItems.length === 0) {
            setPromptItems((items) => {
                // eslint-disable-next-line max-nested-callbacks
                return items.map((item) => {
                    const tempItem = { ...item };
                    if (tempItem.id === ESCALATION_ID) {
                        tempItem.disabled = true;
                        tempItem.isDraggable = false;
                    }
                    return tempItem;
                });
            });
            const updatedData = data.filter((item) => item.id !== ESCALATION_ID);
            setData(updatedData);
            setValue((prevValue) => {
                const tempValue = { ...prevValue };
                delete tempValue[ESCALATION_ID];
                return tempValue;
            });
            setIsEscalationPillDragged(false);
        }
    }, [selectedEngagementOptions]);

    const getSelectedFindings = () => {
        return selectedFindingsOptions
            .filter((option) => !!option)
            .map((finding) => ({
                id: finding.id,
                displayValue: finding.value,
                ...(finding.cid && { cid: finding.cid }),
                eventType: finding.eventType,
            }));
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFindingsDropdownSearchInput(event.target.value);
    };
    const getSelectedUserCohorts = () => {
        return rows
            .flatMap((row) => row.autoCompleteData)
            .filter((dataItem) => dataItem.id !== '0')
            .map((item) => {
                const tempItem = item.kind === 'USER' ? { ...item, value: item.id } : item;
                delete tempItem.icon;
                return tempItem;
            });
    };

    const getSelectedEngagements = () => {
        return selectedEngagementOptions.filter((option) => !!option).map((option) => option.id);
    };

    const handleOnSave = () => {
        const findings = getSelectedFindings();
        const userCohorts = getSelectedUserCohorts();
        const engagementCid = getSelectedEngagements();
        const filter = handleFilters(value, data, checked, connectorProviderMap, currentEngagementOption);

        const newErrors: Record<string, boolean> = {};

        if (filter.actions) {
            if (filter.actions.expirationAction && !value[RESOLUTION_EXPIRY_ACTION_ID]?.actionId) {
                newErrors[RESOLUTION_EXPIRY_ACTION_ID] = true;
            }

            if (filter.actions.resolveNowAction && !value[RESOLVE_NOW_ID].actionId) {
                newErrors[RESOLVE_NOW_ID] = true;
            }
            if (filter.actions.actionButtons) {
                const duplicateIds = findDuplicateActionLabels(filter.actions.actionButtons);
                Array.isArray(filter.actions.actionButtons) &&
                    filter?.actions?.actionButtons?.forEach((action) => {
                        if (!value[action.id]?.actionId || !action?.actionLabel) {
                            newErrors[action.id] = true;
                        }
                        if (duplicateIds.includes(action.id)) {
                            newErrors[action.id] = true;
                        }
                    });
            }
        }
        if (filter.triage && !filter.triage.message) {
            newErrors[TRIAGE_ID] = true;
        }
        if (filter.customMessage && !filter.customMessage.template) {
            newErrors[CUSTOM_MESSAGE_ID] = true;
        }
        if (filter.additionalContext && !filter.additionalContext.data) {
            newErrors[ADDITIONAL_CONTEXT_ID] = true;
        }

        if ((filter.training?.subject?.length ?? 0) > 1000 || (filter.training?.context?.length ?? 0) > 8000) {
            newErrors[TRAINING_ID] = true;
        }

        if (
            filter.redirection?.kind === EngagementRedirectionKind.TEAMS_CHANNEL &&
            !isValidChannelUrl(filter.redirection?.channelId)
        ) {
            newErrors[REDIRECT_ENGAGEMENT_ID] = true;
        }

        setValidationError(newErrors);

        if (!Object.values(newErrors).some((error) => error)) {
            const filters: FlowSpecUpsertDto = {
                id: apiData.id,
                ...filter,
                name: title,
                filter: { cohorts: userCohorts as UserCohortType[] },
                engagementChannelCids: engagementCid,
                triggerFilter: { trigger: findings as FindingsType },
                description: apiData.description,
                status: apiData.status,
            };

            updateEngagementFlow(filters)
                .then(() => navigate(`${ACTIVE_WORKFLOWS_ROUTE}`))
                .catch(() =>
                    addToast({
                        severity: 'error',
                        body: DEFAULT_ERROR_MSG,
                    }),
                );
        }
    };

    const _handleOnPreviewEngagementClick = () => {
        setPreviewMode((prevState) => !prevState);
    };

    const renderCanvasItems = () => {
        const resolutionExpiryPill = data.find((draggedItem) => draggedItem?.id === RESOLUTION_EXPIRY_ACTION_ID);
        const resolveDeadlinePill = data.find((draggedItem) => draggedItem?.id === RESOLUTION_DEADLINE_ID);
        if (!resolveDeadlinePill && resolutionExpiryPill) {
            disablePromptWithId(RESOLUTION_DEADLINE_ID);
            setData([...data, promptItems[2] as MockDataType]);
        }

        return data
            .map((item) => {
                const PromptConfigComponent = item.value;
                if (!PromptConfigComponent) {
                    return null;
                }
                const tempItem: CanvasType = {
                    ...item,
                    value: (
                        <PromptConfigComponent
                            selectedOptions={selectedEngagementOptions}
                            onOptionSelected={handleValueChange}
                            {...value[item.id]}
                            options={value[item.id]?.multiSelect as string[]}
                            id={item.id}
                            checked={checked}
                            onChange={handleChecked}
                            onKeyDown={handleKeyDown}
                            actions={(Array.isArray(actionsData) ? actionsData : []).map((item) => {
                                const serviceKey = getServiceInfoByKey(serviceProfiles, item.serviceKey);
                                return {
                                    id: item.id,
                                    displayValue: item.displayValue,
                                    serviceKey: serviceKey,
                                };
                            })}
                            error={validationError[data.find((dataItem) => dataItem.id === item.id)?.id ?? '']}
                        />
                    ),
                };

                return tempItem;
            })
            .filter(Boolean) as CanvasType[];
    };

    useEffect(() => {
        const isProviderSearched = (item: FindingSpecsWithProviderName) => {
            return item.providerDisplayValue.toLowerCase().includes(findingsDropdownSearchInput.toLowerCase());
        };

        const isFindingSpecSearched = (item: FindingSpecsWithProviderName) => {
            return item.displayValue?.toLowerCase().includes(findingsDropdownSearchInput.toLowerCase());
        };

        const filteredFindingsDropdownItems = findingSpecs.filter(
            (item) => isProviderSearched(item) || isFindingSpecSearched(item),
        );
        setFindingsDropdownItems(filteredFindingsDropdownItems);
    }, [findingsDropdownSearchInput]);

    const handleFindingsDropdownChange = (targetID: string, itemId: string, eventKey: string | null) => {
        setSelectedFindingsOptions((prevSelectedOptions) => {
            const updatedOptions = [...prevSelectedOptions];
            const selectedItem = findingSpecs.find((item) => item.id === targetID);
            const existingItemIndex = updatedOptions.findIndex((item) => item.id === itemId);

            if (existingItemIndex !== -1) {
                updatedOptions[existingItemIndex] = {
                    ...updatedOptions[existingItemIndex],
                    id: selectedItem?.id as string,
                    icon: selectedItem?.icon || '',
                    value: eventKey ?? '',
                    cid: selectedItem?.cid as string,
                    eventType: selectedItem?.kind as string,
                };
            } else {
                updatedOptions.push({
                    id: selectedItem?.id as string,
                    icon: selectedItem?.icon || '',
                    value: eventKey ?? '',
                    cid: selectedItem?.cid as string,
                    eventType: selectedItem?.kind as string,
                });
            }
            return updatedOptions;
        });
        const updatedDropdownItems = findingSpecs.map((item) => ({
            ...item,
            disabled: item.value === eventKey,
        }));

        dispatch.findingSpecsModel.setFindings(updatedDropdownItems);
        setFindingsDropdownItems(updatedDropdownItems);
        setFindingsDropdownSearchInput('');
    };

    const handleDeleteFindingsSelectedItem = (indexToDelete: string) => {
        setSelectedFindingsOptions((prevSelectedOptions) => {
            const updatedOptions = prevSelectedOptions.filter((item) => item.id !== indexToDelete);
            updatedOptions.map((option) => `${option?.icon} - ${option?.value}`).join(', ');
            return updatedOptions;
        });
    };

    return (
        <PageErrorHandlerAndLoaderWrapper
            error={error}
            isLoading={isAPIDataLoading}
            render={() => (
                <div className="create-engagement-page pt-4 overflow-auto custom-scrollbar">
                    <div className="subtitle1 text-uppercase text-text-high-emphasis mb-3 d-flex justify-content-between">
                        <div className="form-group d-flex align-items-center gap-3 w-100">
                            <IoArrowBackOutline onClick={handleBackClick} />
                            <div className="d-flex gap-1 w-70 p-1 align-items-center">
                                <Form.Control
                                    style={{ width: `${inputWidth}px` }}
                                    className="create-engagement-page__title shadow-none bg-transparent border-0 subtitle1 text-text-high-emphasis p-0 m-0 text-truncate"
                                    type="text"
                                    value={title}
                                    onChange={handleTitleChange}
                                    placeholder="Enter title"
                                />
                                <ImPencil
                                    size={20}
                                    className="create-engagement-page__title__edit-icon text-text-low-emphasis h-100"
                                />
                                <span
                                    ref={spanRef}
                                    className="invisible position-absolute create-engagement-page__span"
                                >
                                    {title || ENGAGEMENT_TITLE}
                                </span>
                            </div>
                        </div>
                        <Button
                            variant="primary-500"
                            className="create-engagement-page__save-btn border-0 subtitle2 text-uppercase pe-4 ps-4"
                            onClick={handleOnSave}
                        >
                            {SAVE_TEXT}
                        </Button>
                    </div>
                    <div className="create-engagement-page__body d-flex w-100 gap-3">
                        <div className={containerClasses}>
                            <ExpandableEngagementList
                                selectedFindingsOptions={selectedFindingsOptions}
                                userCohortRows={rows}
                                setUserCohortRows={setRows}
                                selectedEngagementOptions={selectedEngagementOptions}
                                setSelectedEngagementOptions={setSelectedEngagementOptions}
                                findingsDropdownOptions={findingsDropdownItems}
                                onFindingsDropdownChange={handleFindingsDropdownChange}
                                onFindingsDeleteSelectedOption={handleDeleteFindingsSelectedItem}
                                onFindingsSearchChange={handleSearchChange}
                                findingsSearchQuery={findingsDropdownSearchInput}
                                dropdownEngagementOptions={dropdownEngagementOptions as MenuItem[]}
                            />
                            <div
                                className="bg-text-black create-engagement-page__canvas-wrapper h-100 ps-3 pe-3 pt-4 custom-scrollbar overflow-auto"
                                ref={containerRef}
                            >
                                <Canvas
                                    data={renderCanvasItems()}
                                    onDeleteClick={handleDeleteClick}
                                    draggable={draggable}
                                />
                            </div>
                            {/* <Button
                                variant="foundation-blue-200"
                                className="create-engagement-page__body__left__preview-btn position-absolute start-50 translate-middle body1 text-uppercase"
                                onClick={handleOnPreviewEngagementClick}
                            >
                                {isPreviewMode ? BUILD_ENGAGEMENT : PREVIEW_ENGAGEMENT}
                            </Button> */}
                        </div>
                        {rightPanelRenderer()}
                    </div>
                </div>
            )}
        ></PageErrorHandlerAndLoaderWrapper>
    );
};

export default CreateEngagementPage;
