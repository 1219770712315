import { FormData } from 'Core-utils/types';

export const METHOD_OPTIONS = [
    { id: '1', value: 'POST' },
    { id: '2', value: 'GET' },
    { id: '3', value: 'PATCH' },
    { id: '4', value: 'PUT' },
    { id: '5', value: 'DELETE' },
];

export const PAYLOAD_OPTIONS = [
    { id: '1', value: 'JSON' },
    { id: '2', value: 'XML' },
];

export const INITIAL_STATE: FormData = {
    actionName: '',
    description: '',
    url: '',
    selectedMethod: 'POST',
    selectedPayload: 'JSON',
    selectedPayloadValue: '',
    headers: [{ key: '', value: '' }],
    parameters: [{ key: '', value: '' }],
};
