import { useState } from 'react';
import { Stepper } from 'Components/organisms';
import { GeneralInformationStep, RequestConfigurationStep, AdvancedSettingsStep } from './CustomActionForm';
import {
    ACTION_LABELS,
    CUSTOM_ACTION_HEADERS,
    ENTER_VALID_FORMAT,
    INVALID_URL_TEXT,
    SUPPORTING_TEXT,
} from 'Core-utils/constants/constants';
import { Typography } from 'Components/atoms';
import { CustomActionDto } from '@ampsec/platform-client';
import '../styles.scss';

interface CustomActionStepperFormProps {
    handleSave: () => void;
    data: CustomActionDto;
    handleFieldChange: (id: string, value: string) => void;
    handleAddRow: (dataKey: 'headers' | 'parameters') => () => void;
    handleDeleteRow: (dataKey: 'headers' | 'parameters') => (index: number) => () => void;
    handleTableChange: (
        dataKey: 'headers' | 'parameters',
        index: number,
        field: string,
    ) => (value: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
const CustomActionStepperForm = ({
    handleSave,
    data,
    handleAddRow,
    handleDeleteRow,
    handleFieldChange,
    handleTableChange,
}: CustomActionStepperFormProps) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [errors, setErrors] = useState<string[]>([]);

    const handleBackButtonClick = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
    };

    const validateUrl = (url: string): boolean => {
        try {
            new URL(url).hostname;
            return true;
        } catch (error) {
            return false;
        }
    };

    const isValidPayloadFormat = (value: string): boolean => {
        switch (data.meta?.request?.payload?.format) {
            case 'JSON':
                try {
                    JSON.parse(value);
                    return true;
                } catch (error) {
                    return false;
                }
            case 'XML':
                try {
                    const parser = new DOMParser();
                    const parsedDocument = parser.parseFromString(value, 'text/xml');
                    const parserError = parsedDocument.getElementsByTagName('parsererror');
                    if (parserError.length > 0) {
                        return false;
                    }
                    return true;
                } catch (error) {
                    return false;
                }
            default:
                return false;
        }
    };

    const handleNextButtonClick = () => {
        const formErrors: string[] = [];
        if (currentStep === 0) {
            if (!data.displayValue) {
                formErrors.push(`${ACTION_LABELS[2]} ${SUPPORTING_TEXT}`);
            }
        } else if (currentStep === 1) {
            if (!data.meta?.request?.url) {
                formErrors.push(`${ACTION_LABELS[3]} ${SUPPORTING_TEXT}`);
            }
            if (data.meta?.request?.url) {
                if (!validateUrl(data.meta?.request?.url)) {
                    formErrors.push(`${ACTION_LABELS[3]} ${INVALID_URL_TEXT}`);
                }
            }
            if (!data.meta?.request?.method) {
                formErrors.push(`${ACTION_LABELS[4]} ${SUPPORTING_TEXT}`);
            }
            if (data.meta?.request?.payload?.value) {
                if (!isValidPayloadFormat(data.meta?.request?.payload?.value)) {
                    formErrors.push(`${ACTION_LABELS[8]} ${ENTER_VALID_FORMAT}`);
                }
            }
        }
        if (formErrors.length > 0) {
            setErrors(formErrors);
            return;
        }
        setErrors([]);
        setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    };

    const renderHeading = (title: string) => {
        return (
            <Typography variant="body12" color="text-high-emphasis" style={{ textTransform: 'uppercase' }}>
                {title}
            </Typography>
        );
    };

    const steps = [
        {
            stepperName: CUSTOM_ACTION_HEADERS[0],
            stepperContent: (
                <>
                    {renderHeading(CUSTOM_ACTION_HEADERS[0])}
                    <GeneralInformationStep formData={data} handleFieldChange={handleFieldChange} errors={errors} />
                </>
            ),
        },
        {
            stepperName: CUSTOM_ACTION_HEADERS[1],
            stepperContent: (
                <>
                    {renderHeading(CUSTOM_ACTION_HEADERS[1])}
                    <RequestConfigurationStep formData={data} handleFieldChange={handleFieldChange} errors={errors} />
                </>
            ),
        },
        {
            stepperName: CUSTOM_ACTION_HEADERS[2],
            stepperContent: (
                <>
                    {renderHeading(CUSTOM_ACTION_HEADERS[2])}
                    <AdvancedSettingsStep
                        formData={data}
                        handleAddRow={handleAddRow}
                        handleDeleteRow={handleDeleteRow}
                        handleTableChange={handleTableChange}
                    />
                </>
            ),
        },
    ];

    return (
        <Stepper
            steps={steps}
            currentStep={currentStep}
            handleBackButtonClick={handleBackButtonClick}
            handleNextButtonClick={handleNextButtonClick}
            handleSave={handleSave}
        />
    );
};

export default CustomActionStepperForm;
